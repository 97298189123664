import React, { useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import axios from 'axios'
import { useForm } from "react-hook-form";
import { useAlert } from 'react-alert'
import Title from '../../components/Title'

const AddOrder = () => {
	Title('Add Order');
	const { register, handleSubmit, formState: { errors } } = useForm();
	const [desc, setDesc] = useState('');
	const [btnText, setBtnText] = useState('Update');
	const [isSubmit, setIssubmit] = useState(false);
	const alert = useAlert()
	const { id } = useParams();

	const onSubmit = data => {
		setBtnText('Updating...');
		setIssubmit(true);
		data.content = desc;

		console.log(JSON.stringify(data));
		//console.log(data);

		//alert.error('error');
		alert.success('success');
		setIssubmit(false);
		setBtnText('Update');
	}

	return (
		<div className="content-wrapper">
			<div className="content-header">
				<div className="container-fluid mb-2">
					<div className="row">
						<div className="col-sm-6">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><NavLink to="/admin/">Home</NavLink></li>
								<li className="breadcrumb-item"><NavLink to="/admin/orders">Orders</NavLink></li>
								<li className="breadcrumb-item active">Add new</li>
							</ol>
						</div>
						<div className="col-sm-6">
						</div>
					</div>
				</div>
			</div>
			<hr />
			<section className="content">
				<div className="container-fluid p-0 mb-4">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-sm-12 col-md-8 col-lg-9 rounded">
								<div className="form-group p-3 bg-white mb-3 card-border-top">
									<label className="form-label">Title</label>
									<input type="text" className={errors.title ? "form-control input-error " : "form-control"}
										autocomple="off"
										{...register("title", { required: true })}
									/>
								</div>
								<div className="form-group  p-3 bg-white mb-3">
									<label className="form-label">Description</label>

								</div>
							</div>
							<div className="col-sm-12 col-md-4 col-lg-3">
								<div className=" p-3 bg-white card-border-top">
									<div className="form-group">
										<label className="m-0">Status</label>
										<hr />
										<select className="form-select" {...register("status", { required: false })}>
											<option value="ec-pending">Pending Payment</option>
											<option value="ec-processing">Processing</option>
											<option value="ec-on-hold">On hold</option>
											<option value="ec-completed">Completed</option>
											<option value="ec-cancelled">Cancelled</option>
											<option value="ec-refunded">Refunded</option>
											<option value="ec-failed">Failed</option>
										</select>
									</div>
									<div className="form-group mt-3">
										<button className="btn btn-primary" disabled={isSubmit}>{btnText}</button>
									</div>
								</div>
								<div className=" p-3 bg-white card-border-top mt-3">
									<div className="form-group">
										<label className="m-0">Order notes</label>
										<hr />
										<input type="text" className="form-control" id="order_notes" autocomple="off" />
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</section>
		</div>
	)
}

export default AddOrder