import React, { useEffect, useState } from 'react'
import { NavLink, useParams, useHistory } from 'react-router-dom'
import { useForm } from "react-hook-form";
import axios from 'axios'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useAlert } from 'react-alert'
import { GetPostCategories } from '../../components/PostCategories';
import { GetPostTags } from '../../components/PostTags';
import MediaBox from '../../components/MediaBox'
import CustomFields from '../../components/CustomFields';
import { Redirect } from "react-router-dom";
import Title from '../../components/Title'

const EditBlog = () => {
	Title('Edit Post');
	const { id } = useParams();
	const { register, control, handleSubmit, reset, setValue, formState: { errors } } = useForm();
	const [desc, setDesc] = useState('');
	const [btnText, setBtnText] = useState('Update');
	const [isSubmit, setIssubmit] = useState(false);
	const [status, setStatus] = useState(true);
	const [image, setImage] = useState('');
	const alert = useAlert()
	const history = useHistory();

	const onSubmit = async data => {
		setBtnText('Updating...');
		setIssubmit(true);
		const image_id = document.querySelector('#image_id').value;
		data.image_id = image_id;
		data.content = desc;
		try{
		await axios.post('Posts/updatePost/' + id, data)
			.then(response => {
				let resp = response.data;
				alert.success(resp.msg);
				setIssubmit(false);
				setBtnText('Update');
			})
			.catch(error => {
				alert.error(error);
			})
		} 
		catch (error) {
			alert.error(error.message);
		}
	}
	useEffect(() => {
		const fetchPosts = async () => {
			try {
				await axios.get('Posts/getSingle/' + id)
					.then(response => {
						setStatus(response.data.status);

						if (response.data.status) {
							let data = response.data.data;
							console.log(data);
							// Set All default values
							setDesc(data.content);
							setValue('title', data.title);
							setValue('status', data.status);
							 setValue('excerpt', data.excerpt);
							 setValue('customField', data.custom_fields ? data.custom_fields : []);
							 setValue('tags', data.tags);
							 setValue('categories', data.categories);
							 setImage(data.image_id);
						}
					})
					.catch(error => {
						alert.error(error);
					})
			}
			catch (error) {
				alert.error(error.message);
			}
		};
		fetchPosts();
	}, []);
	// Check post found!
	if (!status) {
		history.push('/admin/blogs');
	}
	return (
		<div className="content-wrapper">
			<div className="content-header">
				<div className="container-fluid mb-2">
					<div className="row">
						<div className="col-sm-6">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><NavLink to="/admin/">Home</NavLink></li>
								<li className="breadcrumb-item"><NavLink to="/admin/blogs">Blogs</NavLink></li>
								<li className="breadcrumb-item active">Edit blog</li>
							</ol>
						</div>
						<div className="col-sm-6">
						</div>
					</div>
				</div>
			</div>
			<hr />
			<section className="content">
				<div className="container-fluid p-0 mb-4">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-sm-12 col-md-8 col-lg-8 rounded height-fit mb-3">
								<div className="form-group bg-white mb-3 p-3 card-border-top ">
									<label className="form-label">Title</label>
									<input type="text" className={errors.title ? "form-control input-error " : "form-control"}
										autocomple="off"
										{...register("title", { required: true })}
									/>
								</div>
								<div className="form-group bg-white mb-3 p-3 card-border-top ">
									<label className="form-label">Description</label>
									<CKEditor
										editor={ClassicEditor}
										data={desc}
										onChange={(event, editor) => {
											const data = editor.getData();
											setDesc(data);
										}}

									/>
									<label className="form-label mt-3">Short Description </label>
									<textarea className="form-control" autocomple="off"
										{...register("excerpt", { required: false })}
									></textarea>
								</div>
								<CustomFields register={register} control={control} />
							</div>
							<div className="col-sm-12 col-md-4 col-lg-4 pr-0">
								<div className=" p-3 bg-white card-border-top ">
									<div className="form-group">
										<label className="m-0">Status</label>
										<hr />
										<select className="form-select" {...register("status", { required: false })}>
											<option value="publish">Publish</option>
											<option value="pending">Pending</option>
										</select>
									</div>
									<div className="form-group mt-2">
										<button className="btn btn-primary" disabled={isSubmit}>{btnText}</button>
									</div>
								</div>
								<div className=" p-3 bg-white card-border-top  mt-3">
									<label className="m-0">Category</label>
									<hr />
									<div className="catetag">
										<GetPostCategories register={register} />
									</div>
								</div>
								<div className=" p-3 bg-white card-border-top  mt-3">
									<label className="m-0">Tags</label>
									<hr />
									<div className="catetag">
										<GetPostTags register={register} />
									</div>
								</div>
								<div className="p-3 bg-white card-border-top  mt-3">
									<div className="form-group">
										<label className="m-0">Featured Image</label>
										<hr />
										<MediaBox image_id={image} />
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</section>

		</div>
	)
}

export default EditBlog