import React, { useState, useEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import axios from 'axios'
import Loader from "react-loader-spinner"
import Datatable from 'react-data-table-component'
import { useAlert } from 'react-alert'
import Title from '../../components/Title'

const Blogs = () => {
	Title('Posts');
	const alert = useAlert();
	const [posts, setPosts] = useState([]);
	const [isdelete, setisdelete] = useState(false);
	const [loading, setLoading] = useState(false);

	const fetchPosts = async () => {
		setLoading(true);
		try {
			await axios.get('Posts/getAllPost')
				.then(response => {
					if (response.data.status)
						setPosts(response.data.data);
				})
				.catch(error => {
					alert.error(error);
				})
		}
		catch (error) {
			alert.error(error.message);
		}
		setLoading(false);
	};

	const DeletePost = async (id) => {
		setisdelete(true);
		try {
			await axios.post('Posts/deletePost/' + id)
				.then(response => {
					let resp = response.data;
					alert.success('Post Deleted');
					setisdelete(false);
					fetchPosts();
				})
				.catch(error => {
					alert.error(error);
				})
		}
		catch (error) {
			alert.error(error.message);
		}
	}

	useEffect(() => {
		fetchPosts();
	}, []);

	const columns = [
		{
			name: 'Title',
			selector: row => row.title,
			sortable: true,
			grow: 1

		},
		{
			name: 'Author',
			selector: row => row.user_name,
			sortable: true,
			grow: 1
		},
		{
			name: 'Date',
			selector: row => row.created_at,
			sortable: true,
			wrap: true
			//right: true,
		},
		{
			name: 'Actions',
			wrap: false,
			right: false,
			cell: row => <div>
				<button className="btn btn-danger btn-sm me-2" disabled={isdelete} onClick={() => DeletePost(row.id)} >Delete</button>
				<NavLink to={'/admin/blog/' + row.id} className="btn btn-info btn-sm me-2">Edit</NavLink>
				<NavLink to={'/blog/' + row.post_name} className="btn btn-primary btn-sm">View</NavLink>
			</div>,
			right: true,
		},
	];

	return (
		<div className="content-wrapper">
			<div className="content-header">
				<div className="container-fluid mb-2">
					<div className="row">
						<div className="col-sm-6">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><NavLink to="/admin/">Home</NavLink></li>
								<li className="breadcrumb-item active">Blogs</li>
							</ol>
						</div>
						<div className="col-sm-6 text-sm-end">
							<NavLink to="/admin/add-blog" className="btn btn-sm btn-primary">Add Blog</NavLink>
							<NavLink to="/admin/categories" className="btn btn-sm btn-primary ms-2">Categories</NavLink>
							<NavLink to="/admin/tags" className="btn btn-sm btn-primary ms-2">Tags</NavLink>
						</div>
					</div>
				</div>
			</div>
			<hr />
			{loading &&
				<div className="content-wrapper text-center">
					<Loader
						type="ThreeDots"
						color="#00BFFF"
						height={100}
						width={100}
					/>
				</div>
			}
			<section className="content">
				<div className="container-fluid px-0">
					<div className="row">
						<Datatable
							title="Posts"
							columns={columns}
							data={posts}
							pagination
							highlightOnHover
							noHeader
							className="table"
						/>
					</div>
				</div>
			</section>
		</div>
	)
}

export default Blogs