import Dashboard from "../views/admin/Dashboard";
import Settings from "../views/admin/Settings";

import Users from "../views/admin/Users";
import AddUser from "../views/admin/AddUser";
import EditUser from "../views/admin/EditUser";

import Pages from "../views/admin/Pages";
import AddPage from "../views/admin/AddPage";
import EditPage from "../views/admin/EditPage";

import Products from "../views/admin/Products";
import AddProduct from "../views/admin/AddProduct";
import EditProduct from "../views/admin/EditProduct";
import ProductCategories from "../views/admin/ProductCategories"
import ProductTags from "../views/admin/ProductTags"
import EditProductCategory from "../views/admin/EditProductCategory"
import EditProductTag from "../views/admin/EditProductTag"

import Orders from "../views/admin/Orders";
import AddOrder from "../views/admin/AddOrder";
import EditOrder from "../views/admin/EditOrder";

import Blogs from "../views/admin/Blogs";
import AddBlog from "../views/admin/AddBlog";
import EditBlog from "../views/admin/EditBlog";
import Categories from "../views/admin/Categories"
import Tags from "../views/admin/Tags"
import EditCategory from "../views/admin/EditCategory"
import EditTag from "../views/admin/EditTag"

import Media from "../views/admin/Media"

let DashRoutes = [
  {
    path: "/",
    title: "Dashboard",
    icon: "fa fa-tachometer",
    view: Dashboard,
    layout: "/admin",
    display: true,
    isSingle: false
  },
  {
    path: "/settings",
    title: "Settings",
    icon: "fa fa-cog",
    view: Settings,
    layout: "/admin",
    display: true,
    isSingle: false
  },
  {
    path: "/media",
    title: "Media",
    icon: "fa fa-picture-o",
    view: Media,
    layout: "/admin",
    display: true,
    isSingle: false
  },
  {
    path: "/pages",
    title: "Pages",
    icon: "fa fa-file",
    view: Pages,
    layout: "/admin",
    display: true,
    isSingle: false,
    id: 'pages'
  },
  {
    path: "/add-page",
    title: "Add Page",
    icon: "fa fa-file",
    view: AddPage,
    layout: "/admin",
    display: false,
    isSingle: false,
    id: 'pages'
  },
  {
    path: "/page/",
    title: "Singe Page",
    view: EditPage,
    layout: "/admin",
    display: false,
    isSingle: true
  },
  {
    path: "/blogs",
    title: "Blogs",
    icon: "fa fa-clipboard",
    view: Blogs,
    layout: "/admin",
    display: true,
    isSingle: false
  },
  {
    path: "/add-blog",
    title: "Add Blog",
    icon: "fa fa-user",
    view: AddBlog,
    layout: "/admin",
    display: false,
    isSingle: false
  },
  {
    path: "/blog/",
    title: "Edit Blog",
    view: EditBlog,
    layout: "/admin",
    display: false,
    isSingle: true
  },
  {
    path: "/categories/",
    title: "Categories",
    view: Categories,
    layout: "/admin",
    display: false,
    isSingle: false
  },
  {
    path: "/tags/",
    title: "Tags",
    view: Tags,
    layout: "/admin",
    display: false,
    isSingle: false
  },
  {
    path: "/edit-category/",
    title: "Edit Category",
    view: EditCategory,
    layout: "/admin",
    display: false,
    isSingle: true
  },
  {
    path: "/edit-tag/",
    title: "Edit Tag",
    view: EditTag,
    layout: "/admin",
    display: false,
    isSingle: true
  },
  {
    path: "/users",
    title: "Users",
    icon: "fa fa-user",
    view: Users,
    layout: "/admin",
    display: true,
    isSingle: false
  },
  {
    path: "/add-user",
    title: "Add User",
    icon: "fa fa-user",
    view: AddUser,
    layout: "/admin",
    display: false,
    isSingle: false
  },
  {
    path: "/user/",
    title: "Edit User",
    view: EditUser,
    layout: "/admin",
    display: false,
    isSingle: true
  },
  {
    path: "/products",
    title: "Products",
    icon: "fa fa-product-hunt",
    view: Products,
    layout: "/admin",
    display: true,
    isSingle: false
  },
  {
    path: "/add-product",
    title: "Add Product",
    icon: "fa fa-file",
    view: AddProduct,
    layout: "/admin",
    display: false,
    isSingle: false
  },
  {
    path: "/product/",
    title: "Edit Product",
    view: EditProduct,
    layout: "/admin",
    display: false,
    isSingle: true
  },
  {
    path: "/product-categories/",
    title: "Categories",
    view: ProductCategories,
    layout: "/admin",
    display: false,
    isSingle: false
  },
  {
    path: "/product-tags/",
    title: "Tags",
    view: ProductTags,
    layout: "/admin",
    display: false,
    isSingle: false
  },
  {
    path: "/category/",
    title: "Edit Category",
    view: EditProductCategory,
    layout: "/admin",
    display: false,
    isSingle: true
  },
  {
    path: "/tag/",
    title: "Edit Tag",
    view: EditProductTag,
    layout: "/admin",
    display: false,
    isSingle: true
  },

  {
    path: "/orders",
    title: "Orders",
    icon: "fa fa-shopping-cart",
    view: Orders,
    layout: "/admin",
    display: true,
    isSingle: false
  },
  {
    path: "/order/",
    title: "Edit Order",
    view: EditOrder,
    layout: "/admin",
    display: false,
    isSingle: true
  },
  {
    path: "/add-order",
    title: "Create Order",
    view: AddOrder,
    layout: "/admin",
    display: false,
    isSingle: false
  },
];
export default DashRoutes;