import React, { useState, useEffect } from 'react'
import axios from 'axios';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner"

const MediaBox = (props) => { 
  let [responseData, setResponseData] = useState([]);
  let [isNext, isNextFunc] = useState(false);
  let [pageCount, setCount] = useState(1);
  let [show, setshow] = useState('none');
  let [imageId, setImageId] = useState('');
  let [imgurl, setImgURL] = useState();

  const fetchData = () => { 
    setTimeout(() => {
      axios.get(`https://jsonplaceholder.typicode.com/photos?_page=${pageCount}&_limit=16`)
      .then((response) => {
        setResponseData([...responseData, ...response.data]);
        isNextFunc(true);
        setCount(pageCount + 1);

      })
      .catch((error) => {
        console.log(error);
      });
    }, 1500);
  }

  const selectImage = (id, url) => {
    setImageId(id);
    setImgURL(url);
    setshow('none');
  }
  const openImageBox = () => {
    setshow('block');
  }
  const close = () => {
    setshow('none');
  }
  useEffect(() => {
    fetchData();
    // Default 
    if(props.image_id && imageId === ''){
      axios
      .get(
        `https://jsonplaceholder.typicode.com/photos/${props.image_id}`
      )
      .then((response) => {
        setImgURL(response.data.thumbnailUrl);
        setImageId(props.image_id);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [props]);

  return (
    <>
      <input type="button" value="Select" className="btn btn-outline-primary btn-md d-block shadow-none" onClick={openImageBox} />
      <input type="hidden" id="image_id" defaultValue={imageId} name="image_id" />
      {imgurl &&
        <img src={imgurl} alt="img" className="mt-2 img-thumbnail" />
      }
      <div className="modal fade show  MediaBox" style={{ display: show }} >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div className="modal-content" >

            <div className="modal-header">
              <h4 className="modal-title">Media</h4>
              <button type="button" className="btn-close" onClick={close}></button>
            </div>

            <div className="modal-body" id="scrollableDiv">

              <InfiniteScroll
                dataLength={responseData.length}
                next={fetchData}
                hasMore={isNext}
                loader={
                  <div className="text-center">
                    <Loader
                      type="ThreeDots"
                      color="#00BFFF"
                      height={100}
                      width={100}
                    />
                  </div>
                }
                scrollableTarget="scrollableDiv"
              >
                <div className="row">
                  {responseData.map((data, index) => (
                    <div key={index} className="col-sm-12 col-md-6 col-lg-3 mb-2 media-box">
                      <img key={data.albumId} className="img-fluid" alt="img" src={data.thumbnailUrl} />
                      <div className="overlay py-5 text-center">
                        <a className="btn btn-info" onClick={() => selectImage(data.id, data.thumbnailUrl)} >Select</a>
                      </div>
                    </div>
                  ))}
                </div>
              </InfiniteScroll>

            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default MediaBox