import React, { useState} from 'react'
import { NavLink} from 'react-router-dom'
import { useForm } from "react-hook-form";
//import axios from 'axios'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useAlert } from 'react-alert'
import { Tab, Row, Col, Nav } from 'react-bootstrap'; //  Tabs,
import MediaBox from '../../components/MediaBox';
import MultiMediaBox from '../../components/MultiMediaBox';
import CustomFields  from '../../components/CustomFields';
import { GetProductCategories } from '../../components/ProductCategories';
import { GetProductTags } from '../../components/ProductTags';
import Title from '../../components/Title'

const AddProduct = () => {
	Title('Add Product');
	const { register, control,  handleSubmit, reset, formState: { errors } } = useForm();
	const [desc, setDesc]  = useState('');
	const [btnText, setBtnText] = useState('Publish');
	const [isSubmit, setIssubmit] = useState(false);
	const alert = useAlert()

	const onSubmit = async data => {
		setBtnText('Publishing...');
		setIssubmit(true);
		const image_id = document.querySelector('#image_id').value;
		const galleries = document.querySelector('#galleries').value;

		data.image_id  = image_id;
		data.galleries  = galleries;
		data.content = desc;

		console.log(JSON.stringify(data));
		
		reset(); 
		//alert.error('error');
		alert.success('success');
		setIssubmit(false);
		setBtnText('Publish');
	}

	return (
		<div className="content-wrapper">
			<div className="content-header">
				<div className="container-fluid mb-2">
					<div className="row">
						<div className="col-sm-6">
							<ol className="breadcrumb">
				              <li className="breadcrumb-item"><NavLink to="/admin/">Home</NavLink></li>
				              <li className="breadcrumb-item"><NavLink to="/admin/products">Products</NavLink></li>
				              <li className="breadcrumb-item active">Add Product</li>
				            </ol>
						</div>
						<div className="col-sm-6">
						</div>
					</div>
				</div>
			</div>
			<hr />
			<section className="content">
				<div className="container-fluid p-0 mb-4">
					<form onSubmit = {handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-sm-12 col-md-8 col-lg-8 rounded height-fit mb-2">
								<div className="form-group bg-white p-3 mb-3 card-border-top">
									<label className="form-label">Product Name</label>
									<input type="text" 
									className={ errors.title ? "form-control input-error " : "form-control" }
										autocomple="off" 
		                                {...register("title", { required: true })}
									 />
								</div>
								<div className="form-group bg-white p-3 mb-3 card-border-top">
									<label className="form-label">Description</label>
									<CKEditor
					                    editor={ ClassicEditor }
					                    data= {desc}
					                    onChange={ ( event, editor ) => {
					                        const data = editor.getData();
					                        setDesc(data);
					                    } }
					                   
					                />
									<label className="form-label mt-3">Short Description </label>
									<textarea className="form-control" autocomple="off" 
		                                {...register("except", { required: false })} 
									 ></textarea>
								</div>
								<div className="form-group product-data bg-white p-3 mb-3 card-border-top">
									<label>Product Data</label><hr />
									 <Tab.Container defaultActiveKey="general">
							            <Row>
							                <Col sm={3}>

							                  <Nav variant="pills" className="flex-column">

							                    <Nav.Item>
							                      <Nav.Link eventKey="general">General</Nav.Link>
							                    </Nav.Item>

							                    <Nav.Item>
							                      <Nav.Link eventKey="shipping">Shipping</Nav.Link>
							                    </Nav.Item>
							               
							                  </Nav>

							                </Col>
							                <Col sm={9}>
							                  <Tab.Content>

							                    <Tab.Pane eventKey="general">
							                    	<div className="form-group row mb-3">
													    <label htmlFor="reguprice" className="col-sm-4">Regular Price($)</label>
													    <div className="col-sm-8">
													      <input type="text" className="form-control" id="reguprice" 
													      	autocomple="off" 
		                                					{...register("regular_price", { required: false })}  	
													      />
													    </div>
													  </div>
													  <div className="form-group row mb-3">
													    <label htmlFor="saleprice" className="col-sm-4 col-form-label">Sale Price($)</label>
													    <div className="col-sm-8">
													      <input type="text" className="form-control" id="saleprice"
													      	autocomple="off" 
		                                					{...register("sale_price", { required: false })}  
													       />
													    </div>
													  </div>
													  <div className="form-group row mb-3">
													    <label htmlFor="sku" className="col-sm-4 col-form-label">SKU</label>
													    <div className="col-sm-8">
													      <input type="text" className="form-control" id="sku"
													      	autocomple="off" 
		                                					{...register("sku", { required: false })}  
													       />
													    </div>
													  </div>
													  <div className="form-group row">
													    <label htmlFor="stock_status" className="col-sm-4 col-form-label">Stock Status</label>
													    <div className="col-sm-8">
													     	<select id="stock_status" className="form-select" {...register("stock_status", { required: false })}>
																<option value="in-stock">In Stock</option>
																<option value="out-of-stock">Out of stock</option>
															</select>
													    </div>
													  </div>
							                    </Tab.Pane>
							                    <Tab.Pane eventKey="shipping">
							                    	 <div className="form-group row mb-3">
													    <label htmlFor="weight" className="col-sm-4 col-form-label">Weight(kg)</label>
													    <div className="col-sm-8">
													      <input type="text" className="form-control" id="weight"
													      	autocomple="off" 
		                                					{...register("weight", { required: false })}  
													       />
													    </div>
													  </div>
							                    	<div className="form-group row mb-3">
													    <label htmlFor="shipping_class" className="col-sm-4 col-form-label">Shipping Class</label>
													    <div className="col-sm-8">
													     	<select id="shipping_class" className="form-select" {...register("shipping_class", { required: false })}>
																<option value="one">One </option>
																<option value="two">Two</option>
															</select>
													    </div>
													  </div>
							                    </Tab.Pane>

							                  </Tab.Content>
							                </Col>
							              </Row>
							            </Tab.Container>
								</div>	
								<CustomFields register = {register} control ={control} />
							</div>	
							<div className="col-sm-12 col-md-4 col-lg-4">
								<div className=" p-3 bg-white card-border-top">
									<div className="form-group">
										<label className="m-0">Status</label>
										<hr />
										<select className="form-select" {...register("status", { required: false })}>
											<option value="publish">Publish</option>
											<option value="pending">Pending</option>
										</select>
									</div>
									<div className="form-group mt-3">
										<button className="btn btn-primary" disabled={ isSubmit  }>{btnText}</button>
									</div>
								</div>
								<div className=" p-3 bg-white card-border-top mt-3">
									<label className="m-0">Category</label>
									<hr/>
									<div className="catetag">
										<GetProductCategories register = {register} />
									</div>
								</div>
								<div className=" p-3 bg-white card-border-top mt-3">
									<label className="m-0">Tags</label>
									<hr/>
									<div className="catetag">
										<GetProductTags register = {register} />
									</div>
								</div>
								<div className=" p-3 bg-white card-border-top mt-3">
									<div className="form-group">
										<label className="m-0">Product image</label>
										<hr />
										<MediaBox />
									</div>
								</div>	
								<div className=" p-3 bg-white card-border-top mt-3">
									<div className="form-group">
										<label className="m-0">Product Gallery</label>
										<hr />
										<MultiMediaBox />
									</div>
								</div>	
							</div>
						</div>
					</form>
				</div>
			</section>
		</div>
	)
}

export default AddProduct